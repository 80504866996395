/* App Container */
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Layout */
  .container {
    display: flex;
    justify-content: space-between;
  }
  
  .page-content {
    flex: 4;
    padding: 0px 20px 0px 0px;
  }
  
  .page-content-side {
    flex: 1;
    padding: 0px 0px 0px 20px;
  }
  
  .album-cover2 {
    width: 100%;
    max-width: 100%;
  }
  
  .album-title {
    font-size: 1.5rem;
  }
  
  .album-blurb {
    font-size: 1rem;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .container {
      flex-direction: column; /* Stack columns on top of each other */
    }
  
    .nav-column2,
    .video-column2 {
      flex: 1; /* Equal width for both columns on mobile */
    }
  }
  