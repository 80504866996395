/* Global Styles */
html, body {
  height: 100vh;
  margin: 0;
}

/* App Container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app {
  padding: 10px;
}

/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

/* Set font to Open Sans */
body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

p {
  font-size: 20px;
}

/* Styling */
body {
  margin: 0;
  background-color: #06063C;
  color: #ffffff;
  padding: 10px;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.header-logo {
  max-height: 40px; /* Adjust the maximum height of the logo as needed */
}

/* Add responsive styles for mobile so that header-logo is small */
@media (max-width: 768px) {
  .header-logo {
    max-height: 25px;
  }
}

/* Add these styles to your existing CSS */

.menu-icon {
  float: right;
  width: 20px;
  font-size: 48px; /* Adjust the font size as needed */
  padding-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

/* Add media query for mobile to make menu icon smaller */
@media (max-width: 768px) {
  .menu-icon {
    width: 10px;
    font-size: 36px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the overlay is on top of other elements */
}

.header-menu {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  height: 100%; /* Ensure the menu takes up the full height */
}

.header-menu a {
  text-decoration: none;
  color: #fff; /* Adjust text color as needed */
  font-size: 30px;
  margin-bottom: 10px; /* Add spacing between menu items */
}

.menu {
  float: right;
  margin-top: 10px;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  display: inline-block;
  margin-right: 20px;
}

.menu a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

/* Add media query for mobile to make menu font size smaller */
@media (max-width: 768px) {
  .menu a {
    font-size: 14px;
  }
}

.menu a:hover {
  color: #61dafb;
}


/* Main Content Styles */
.main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Footer Styles */
.footer {
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 12px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: right;
  align-items: center;
}

.footer-statement {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 20px;
  text-transform: uppercase;
}

/* Album Image Styles */
.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.album-image {
  width: 30vw; /* Adjust the width of the images as needed */
  height: 30vw; /* Adjust the height of the images as needed */
  margin: 20px; /* Add margin between the images */
}

/* Add effect on hover make it look like the image is pressed down */
.album-image:hover {
  transform: translateY(2px);
  /* add ease transition */
  transition: all 0.2s ease-in-out;
  /* add a drop shadow */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  /* add a slight tint */
  filter: brightness(90%);
}

/* Media query for medium screens (e.g., tablet) */
@media (max-width: 1400px) {
  .album-image {
    width: 25vw; /* Adjust the width of the images as needed */
    height: 25vw; /* Adjust the height of the images as needed */
  }
}


/* Media query for small screens (e.g., mobile) */
@media (max-width: 768px) {
  .album-image {
    width: 100%; /* Make the images take up the full width */
    height: auto; /* Auto height to maintain aspect ratio */
    margin: 15px 0; /* Adjust margin for better spacing */
  }
}


.donate-button {
  background-color: #41C3AC;
  color: #222;
  font-weight: 300;
  font-size: 16px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border: none;
  border-radius: 0px;
  /* Add the following styles for responsiveness */
  margin-bottom: 10px; /* Adjust the margin as needed */
  transition: background-color 0.3s ease;
}

.donate-button:hover {
  background-color: #d9d9d9;
  color: #222;
}

.content-image {
  width: 40%;
  float: right;
  height: auto;
  margin: 0 0 20px 20px;
  border: 1px solid #d9d9d9;
  /* Add drop shadow */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

/* Add a mobile media query for the content image */
@media (max-width: 768px) {
  .content-image {
    width: 100%;
    float: none;
    margin: 0 0 0px 0;
  }
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #f2f2f2;
  color: #222;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
