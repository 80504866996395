/* App Container */
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  /* Layout */
  .container {
    display: flex;
    justify-content: space-between;
  }
  
  .nav-column2 {
    flex: 1;
    padding: 0px 20px 0px 0px;
  }
  
  .video-column2 {
    flex: 4;
    padding: 0px 0px 0px 20px;
  }
  
  .album-cover2 {
    width: 100%;
    max-width: 100%;
  }

  /* Add media query for mobile devices */
  @media (max-width: 768px) {
    .album-cover2 {
      width: 50%;
      max-width: 100%;
      float: left;
      padding-right: 10px;
    }
  }
  
  .album-title {
    font-size: 1.5rem;
  }


  
  .album-blurb {
    font-size: 1rem;
  }

    /* Add media query for mobile devices */
    @media (max-width: 768px) {
      .album-title {
        font-size: 1.2rem;
      }
      .album-blurb {
        font-size: 1rem;
      }
    }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .container {
      flex-direction: column; /* Stack columns on top of each other */
    }
  
    .nav-column2,
    .video-column2 {
      flex: 1; /* Equal width for both columns on mobile */
      padding: 0px;
    }
  }

  /* Swap the video and nav columns on mobile */
  @media (max-width: 768px) {
    .container {
      flex-direction: column-reverse; /* Reverse the column order */
    }
  }
  
/* Button styles */
.album-button {
  background-color: #42498f;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  padding: 8px;
  margin: 4px;
  cursor: pointer;
  border: none;
  border-radius: 0px;
  /* Add the following styles for responsiveness */
  margin-bottom: 10px; /* Adjust the margin as needed */
  transition: background-color 0.3s ease;
}

/* Mobile styles for button */
@media (max-width: 768px) {
  .album-button {
    font-size: 14px;
    padding: 5px;
    margin: 4px;
    width: 40%; /* Make the button width 100% of the container */
  }
}

/* Hover effect */
.album-button:hover {
  background-color: #d9d9d9;
  color: #222;
}

.contents-box {
  float: left;
}